<template>
  <div class="common-expense">
    <Actions :items="commonExpense.actions" :right="true" />
    <MobileTable
      v-model:selection="store.state.commonExpense.selectedCommonExpenses"
      :label="$route.name"
      :columns="commonExpense.columns"
      :data="store.state.commonExpense.commonExpenses"
      :rows="50"
    />
    <DesktopTable
      v-model:selection="store.state.commonExpense.selectedCommonExpenses"
      :label="$route.name"
      :columns="commonExpense.columns"
      :data="store.state.commonExpense.commonExpenses"
      :rows="50"
    />
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import Actions from "../../components/shared/Actions.vue";
import MobileTable from "../../widgets/tables/MobileTable";
import DesktopTable from "../../widgets/tables/DesktopTable";
import { onBeforeMount, reactive } from "vue";
import store from "../../store";
import { actions } from "../../constants";

export default {
  components: { MobileTable, DesktopTable, Actions },

  setup() {
    const router = useRouter();
    const route = useRoute();

    const onDetail = () => {
      if (store.state.commonExpense.selectedCommonExpenses.length !== 1) {
        alert("Debe seleccionar un registro!");
      } else {
        router.push("/gastoscomunes/detalle");
      }
    };

    const onDownloadExcel = async () => {
      const request = {
        fileName: "Gastos Comunes",
        headers: commonExpense.columns.map((item) => item.header),
        data: store.state.commonExpense.commonExpenses.map((item) => [
          item.period,
          `${item.charges}`,
          item.chargeAmount,
          item.closed,
          item.updatedDate,
        ]),
      };
      store.commit("setLoading", true);
      await store.dispatch(actions.commonExpenseActions.downloadExcel, request);
      store.commit("setLoading", false);
    };

    const commonExpense = reactive({
      columns: [
        {
          field: "period",
          header: "Período",
          sort: true,
          period: true,
        },
        {
          field: "charges",
          header: "Cobros",
          sort: true,
        },
        {
          field: "chargeAmount",
          header: "Monto cobrado",
          sort: true,
          currency: true,
        },
        {
          field: "closed",
          header: "Cerrado",
          sort: true,
        },
        {
          field: "updatedDate",
          header: "Última modificación",
          sort: true,
        },
      ],
      actions: [
        {
          name: "Detalle",
          action: onDetail,
          hidden: true,
        },
        {
          name: "Descargar Excel",
          action: onDownloadExcel,
          hidden: true,
        },
      ],
    });

    onBeforeMount(async () => {
      store.commit("setLoading", true);
      store.commit("setSelectedCommonExpenses", []);
      const finded = store.state.general.breadcrumbs.find(
        (item) => item.to === route.path
      );
      if (!finded)
        store.commit("addBreadcrumb", { label: route.name, to: route.path });
      await store.dispatch(
        actions.commonExpenseActions.findAll,
        store.state.general.selectedBuilding
      );
      await store.dispatch("NOTIFICATION_DELETE", {
        userId: store.state.auth.user.id,
        notificationTypeId: 2,
      });
      store.commit("setLoading", false);
    });

    return { store, commonExpense };
  },
};
</script>

<style scoped></style>
